import React, { useState, useRef, useEffect } from "react";
import { graphql, Link } from "gatsby";
import CleanLayout from "../layouts/Clean";
import { SEO, Subtitle, Text, Title, PostPreview, Label } from "components";
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { annotate } from "rough-notation";
import useReadingTime from "use-reading-time";
import renderDate from "helpers/renderDate";
import { Helmet } from "react-helmet";
import { Disqus, CommentCount } from "gatsby-plugin-disqus";

function slugify(string) {
  return String(string)
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");
}

function Hero({ image, children, title }) {
  return (
    <div style={{ display: "grid" }}>
      {/* You can use a GatsbyImage component if the image is dynamic */}
      <GatsbyImage
        style={{
          gridArea: "1/1",
          minHeight: "50vh",
          maxHeight: "100vh",
          // You can set a maximum height for the image, if you wish.
          // maxHeight: 600,
        }}
        layout="fullWidth"
        // You can optionally force an aspect ratio for the generated image
        // aspectRatio={16 / 10}
        // This is a presentational image, so the alt should be an empty string
        alt={title}
        // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
        image={image}
        objectFit="cover"
        formats={["auto", "webp", "avif"]}
        imgClassName="filter brightness-40 shadow-portfolio"
      />
      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
          // This centers the other elements inside the hero component
          placeItems: "center",
          display: "grid",
        }}
      >
        {/* Any content here will be centered in the component */}
        {children}
      </div>
    </div>
  );
}

const BlogPost = ({ data }) => {
  const [summary, setSummary] = useState([]);

  const annotateRefs = useRef([]);
  annotateRefs.current = [];

  const articleContent = useRef();
  const { readingTime, wordsCount } = useReadingTime(articleContent);

  const tryAnnotate = (el) => {
    const target = el.target.querySelector("span.utility-cl");
    if (!target) return;
    const annotation = annotate(target, {
      type: "highlight",
      color: "#ff1d4d",
      strokeWidth: 8,
      animationDuration: 2000,
    });
    annotation.show();
  };

  useEffect(() => {
    annotateRefs.current.forEach((el) => {
      el.addEventListener("mouseenter", tryAnnotate(el));
    });
  }, []);

  const getOptions = ({ color }) => ({
    renderText: (text) => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
    renderMark: {
      // eslint-disable-next-line
      [MARKS.BOLD]: (text) => (
        <Text
          disableAnimation
          weight="black"
          color={color}
          size="blog"
          highlight
        >
          {text}
        </Text>
      ),
    },
    renderNode: {
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className="list-disc">
          <Text size="blog" disableAnimation color={color}>
            {children}
          </Text>
        </ul>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol>
          <Text size="blog" disableAnimation color={color}>
            {children}
          </Text>
        </ol>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <li>
          <Text size="blog" disableAnimation color={color}>
            {children}
          </Text>
        </li>
      ),
      // eslint-disable-next-line
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <>
          <p>
            <Text size="blog" disableAnimation color={color}>
              {children}
            </Text>
          </p>
        </>
      ),
      // eslint-disable-next-line
      [BLOCKS.HEADING_6]: (node, children) => (
        <div className="mt-12 mb-1">
          <h6>
            <Subtitle>{children}</Subtitle>
          </h6>
        </div>
      ),
      // eslint-disable-next-line
      [BLOCKS.HEADING_5]: (node, children) => (
        <div className="mt-12 mb-1">
          <h5>
            <Subtitle big>{children}</Subtitle>
          </h5>
        </div>
      ),
      // eslint-disable-next-line
      [BLOCKS.HEADING_4]: (node, children) => (
        <div className="mt-12 mb-1">
          <h4>
            <Subtitle big>{children}</Subtitle>
          </h4>
        </div>
      ),
      // eslint-disable-next-line
      [BLOCKS.HEADING_3]: (node, children) => {
        summary.find(({ slug }) => {
          return String(slug) !== String(slugify(children));
        });
        // setSummary((prevSummary) => [
        //   ...prevSummary,
        //   { title: children[0], slug: slugify(children) },
        // ]);
        return (
          <div className="mt-12 mb-1">
            <h3>
              <Title disableAnimation margin size="small">
                {children}
              </Title>
            </h3>
          </div>
        );
      },
      // eslint-disable-next-line
      [BLOCKS.HEADING_2]: (node, children) => {
        const found = summary.find(({ slug }) => {
          return String(slug) === String(slugify(children));
        });
        if (!found)
          setSummary((prevSummary) => [
            ...prevSummary,
            { title: children[0], slug: `${slugify(children)}` },
          ]);
        return (
          <div className="mt-12 mb-1" id={`${slugify(children)}`}>
            <Title disableAnimation size="xl" headingLevel="h2">
              {children}
            </Title>
          </div>
        );
      },

      // eslint-disable-next-line
      [INLINES.EMBEDDED_ENTRY]: (node, children) => {
        // target the contentType of the EMBEDDED_ENTRY to display as you need
        if (node.data.target.sys.contentType.sys.id === "blogPost") {
          return (
            <a href={`/blog/${node.data.target.fields.slug}`}>
              {" "}
              {node.data.target.fields.title}
            </a>
          );
        }
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        // target the contentType of the EMBEDDED_ENTRY to display as you need
        if (node.data.target?.sys?.contentType?.sys?.id === "codeBlock") {
          return (
            <pre>
              <code>{node.data.target.fields.code}</code>
            </pre>
          );
        }

        if (node.data.target?.sys?.contentType?.sys?.id === "videoEmbed") {
          return (
            <iframe
              src={node.data.target.fields.embedUrl}
              height="100%"
              width="100%"
              frameBorder="0"
              scrolling="no"
              title={node.data.target.fields.title}
              allowFullScreen={true}
            />
          );
        }
      },

      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        // render the EMBEDDED_ASSET as you need
        const { gatsbyImageData } = node.data.target;
        if (!gatsbyImageData) {
          // asset is not an image
          return null;
        }
        return (
          <div className="my-12 flex items-center justify-center">
            <GatsbyImage image={gatsbyImageData} />
          </div>
        );
      },
    },
  });

  const {
    allContentfulBlogPost: {
      nodes: [post],
    },
  } = data;

  let disqusConfig = {
    // url: `${config.siteUrl+location.pathname}`,
    identifier: post.id,
    title: post.title,
  };

  const { title } = post;
  return (
    <CleanLayout color="white">
      <SEO
        keywords={post?.tags ?? []}
        title={post.title}
        image={getSrc(post?.image?.gatsbyImageData)}
        metaDescription={post.description.description}
        description={post.description.description}
        meta={[
          {
            name: "author",
            content: post?.author?.name,
          },
        ]}
        article={{ post: post }}
      />

      <div className="w-screen z-20">
        <Hero
          image={getImage(post.imageWithoutTitle ?? post.image)}
          title={title}
        >
          <div className=" my-24 md:my-48 xl:my-72 px-4 md:pl-44 md:pr-72 2xl:pl-96 2xl:pr-96">
            <div className="flex my-4 justify-start items-center">
              <div className="mr-4">
                {/* <Text disableAnimation  size="small" color="white">
                  | {post.category.name}
                </Text> */}
                <Link to={`/${post.category.slug}/`}>
                  <Label {...post?.category} />
                </Link>
              </div>
              <div className="mr-4">
                <Text
                  disableAnimation
                  size="small"
                  color="white"
                  uppercase
                  opaque
                >
                  {renderDate(post?.createdAt)}{" "}
                </Text>
              </div>

              <div className="">
                <Text disableAnimation size="small" color="white">
                  {readingTime} min di lettura
                </Text>
              </div>
            </div>
            <Title disableAnimation color="white" headingLevel="h1">
              {post.title}
            </Title>
            <div className="flex flex-row z-30 my-2 items-center">
              {post.author?.picture && (
                <div className="mr-2 transform hover:rotate-360 transition-all duration-700">
                  <Link to={`/consulente/${post.author.slug}/`}>
                    <GatsbyImage
                      objectFit="cover"
                      image={getImage(post.author?.picture)}
                      className="h-10 w-10 rounded-full "
                      alt={`${title} image`}
                    />
                  </Link>
                </div>
              )}

              <div className="flex flex-col">
                <Text disableAnimation color="white" size="small">
                  <Link to={`/consulente/${post.author.slug}/`}>
                    {post.author?.name ?? "Cosimo Zecchi"}
                  </Link>
                </Text>
                <Text disableAnimation color="white" size="smaller" opaque>
                  <Link to={`/consulente/${post.author.slug}/`}>
                    {post.author?.tagline ?? "..."}
                  </Link>
                </Text>
              </div>
            </div>
          </div>
        </Hero>
        {(summary.length > 0 || post.introduction) && (
          <div className="flex flex-col bg-black  px-4 md:pl-44 md:pr-72 2xl:pl-96 2xl:pr-96  py-24">
            {post.introduction && (
              <>
                <Title disableAnimation color="gray">
                  tl;dr
                </Title>
                <div className="pb-8">
                  {renderRichText(
                    post.introduction,
                    getOptions({ color: "white" })
                  )}
                </div>
              </>
            )}
            {summary.length > 0 && (
              <div
                className={`hidden md:grid grid-flow-col grid-cols-2 grid-rows-${Math.ceil(
                  summary.length / 2
                )} auto-rows-min py-4 gap-x-8 gap-y-2`}
              >
                {summary.map(
                  ({ title, slug }, index) =>
                    title && (
                      <div key={index} className="group">
                        <Link
                          className="flex flex-row items-end"
                          key={index}
                          to={`#${slug}`}
                        >
                          <span className="text-4xl text-white opacity-70 group-hover:text-primary group-hover:opacity-100 transition-all duration-700 w-10">
                            {index + 1}.{" "}
                          </span>
                          <Text disableAnimation color="white">
                            {title}
                          </Text>
                        </Link>
                      </div>
                    )
                )}
              </div>
            )}
            {summary.length > 0 && (
              <div
                className={`grid md:hidden grid-flow-row grid-cols-1 grid-rows-${Math.ceil(
                  summary.length
                )} auto-rows-min py-4 gap-x-8 gap-y-2`}
              >
                {summary.map(
                  ({ title, slug }, index) =>
                    title && (
                      <div key={index} className="group">
                        <Link
                          className="flex flex-row items-start"
                          key={index}
                          to={`#${slug}`}
                        >
                          <span className="text-3xl text-white opacity-70 group-hover:text-primary group-hover:opacity-100 transition-all duration-700 w-10 mr-2 flex-shrink-0">
                            {index + 1}.{" "}
                          </span>
                          <div className="mt-2">
                            <Text disableAnimation color="white">
                              {title}
                            </Text>
                          </div>
                        </Link>
                      </div>
                    )
                )}
              </div>
            )}
          </div>
        )}

        <div className="px-4 md:pl-44 md:pr-72 2xl:pl-96 2xl:pr-96 2xl:mr-24 my-16 flex flex-col">
          <div className="" ref={articleContent}>
            {renderRichText(post.text, getOptions({ color: "black" }))}
          </div>
        </div>
        {/* {post?.disqus && (
          <div className="px-4 md:pl-44 md:pr-72 2xl:pl-96 2xl:pr-96 my-16 flex flex-col">
            <Disqus config={disqusConfig} />
          </div>
        )} */}
        <div className="px-4 md:pl-44 md:pr-72 2xl:pl-96 2xl:pr-96 my-16 flex flex-col">
          {post?.related?.length > 0 && (
            <>
              <div className="md:px-16 my-8">
                <Title disableAnimation size="small" center color="black">
                  Volevi leggerne altri vero? Eccoli!
                </Title>
              </div>
              <div className="flex flex-col md:flex-row">
                {post.related.map((article, index) => (
                  <div key={index} className="px-8">
                    <PostPreview {...article} image={getImage(article.image)} />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </CleanLayout>
  );
};

export const query = graphql`
  query getPost($slug: String!) {
    allContentfulBlogPost(
      filter: { slug: { eq: $slug }, node_locale: { eq: "it" } }
    ) {
      nodes {
        author {
          bio {
            bio
          }
          blog_post {
            slug
            title
            subtitle {
              subtitle
            }
          }
          instagram
          linkedin
          name
          tagline
          picture {
            gatsbyImageData
          }
          slug
        }
        introduction {
          raw
        }
        category {
          color
          name
          slug
          title
        }

        createdAt(locale: "it")
        description {
          description
        }
        embedform
        image {
          gatsbyImageData
        }
        imageWithoutTitle {
          gatsbyImageData
        }
        slug
        subtitle {
          subtitle
        }
        text {
          raw
          references {
            ... on ContentfulAsset {
              contentful_id
              __typename
              gatsbyImageData
            }
          }
        }
        title
        disqus
        tags
        related {
          author {
            name
            slug
            tagline
            picture {
              gatsbyImageData
            }
          }
          category {
            name
            color
            slug
          }
          title
          createdAt
          image {
            gatsbyImageData
            file {
              url
            }
          }
          introduction {
            raw
          }
          slug
          subtitle {
            subtitle
          }
        }
      }
    }
  }
`;

export default BlogPost;
